<template>
  <svg
    id="Group_360"
    data-name="Group 360"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="34.225"
    height="32.886"
    viewBox="0 0 34.225 32.886"
  >
    <g id="Group_359" data-name="Group 359">
      <path
        id="Path_118"
        data-name="Path 118"
        d="M22.757,19.108l.163-.014c.157.21.3.43.472.629a17.4,17.4,0,0,0,2.058,2.336,16.523,16.523,0,0,0,5.056,2.548,9.764,9.764,0,0,0,2.228.472,1.58,1.58,0,0,0,1.112-.322c.7-.643.392-1.625-.591-2.01-.891-.349-1.8-.662-2.69-1.006a8.361,8.361,0,0,1-4.689-3.869c-.811-1.533-1.681-3.037-2.555-4.536A5,5,0,0,0,19.276,10.5c-1.009-.071-2.023-.064-3.035-.108a5.305,5.305,0,0,0-5.129,2.891c-.778,1.349-1.49,2.736-2.235,4.1A6.9,6.9,0,0,1,6.4,20.38c-.909.512-1.872.927-2.816,1.374-.96.454-1.935.876-2.886,1.348a1.235,1.235,0,0,0-.661,1.385,1.171,1.171,0,0,0,1.206.774,4.55,4.55,0,0,0,.789-.088,17.77,17.77,0,0,0,5.007-1.882,8.628,8.628,0,0,0,3.678-3.13,6.056,6.056,0,0,1,.512-.744.471.471,0,0,1,.355-.174c.09.015.2.188.219.305.252,1.432.485,2.868.735,4.3a.726.726,0,0,1-.5.874c-1.436.65-2.875,1.3-4.294,1.984A8.678,8.678,0,0,0,4.777,28.83a2.1,2.1,0,0,0,.735,3.439,9.048,9.048,0,0,0,2.779.593,31.935,31.935,0,0,0,7.025-.766c.606-.1,1.211-.2,1.976-.334-.22-.231-.325-.433-.484-.493-1.173-.442-2.354-.86-3.537-1.275-.5-.174-1-.321-1.5-.481l.044-.221a2.077,2.077,0,0,1,.585-.055c1.424.277,2.872.481,4.258.889,1.885.556,3.7,1.343,5.587,1.9a12.635,12.635,0,0,0,6.727.265,2.006,2.006,0,0,0,1.4-3.045,4.592,4.592,0,0,0-2.352-2.223c-1.762-.824-3.567-1.557-5.366-2.3a.773.773,0,0,1-.581-.924c.191-1.389.353-2.782.536-4.172a3.5,3.5,0,0,1,.151-.523M12.464,4.766a4.7,4.7,0,0,0,4.572,4.718,4.743,4.743,0,1,0-4.572-4.718"
        transform="translate(0 0)"
        fill="currentColor"
      />
      <path
        id="Path_119"
        data-name="Path 119"
        d="M22.756,57.443a3.557,3.557,0,0,0-.151.523c-.182,1.39-.345,2.783-.536,4.172a.773.773,0,0,0,.581.923c1.8.744,3.6,1.477,5.366,2.3a4.592,4.592,0,0,1,2.352,2.222,2.006,2.006,0,0,1-1.4,3.045,12.632,12.632,0,0,1-6.727-.265c-1.884-.56-3.7-1.348-5.587-1.9-1.387-.409-2.834-.612-4.258-.889a2.092,2.092,0,0,0-.585.055l-.044.221c.5.159,1.005.307,1.5.481,1.182.415,2.364.833,3.537,1.275.159.06.264.262.484.493-.765.13-1.37.236-1.976.334a31.925,31.925,0,0,1-7.025.766A9.04,9.04,0,0,1,5.511,70.6a2.1,2.1,0,0,1-.735-3.439,8.686,8.686,0,0,1,2.963-2.122c1.419-.687,2.858-1.333,4.294-1.984a.726.726,0,0,0,.5-.874c-.25-1.433-.482-2.869-.735-4.3-.02-.117-.13-.291-.219-.305a.471.471,0,0,0-.355.174,6.012,6.012,0,0,0-.512.744,8.629,8.629,0,0,1-3.678,3.129,17.769,17.769,0,0,1-5.007,1.882,4.532,4.532,0,0,1-.789.088,1.172,1.172,0,0,1-1.206-.774,1.236,1.236,0,0,1,.661-1.385c.951-.472,1.927-.894,2.886-1.348.944-.447,1.908-.863,2.817-1.374a6.9,6.9,0,0,0,2.478-2.991c.745-1.368,1.457-2.755,2.235-4.1a5.306,5.306,0,0,1,5.129-2.891c1.011.044,2.026.038,3.034.108a5,5,0,0,1,4.046,2.835c.874,1.5,1.743,3,2.555,4.536a8.359,8.359,0,0,0,4.689,3.869c.893.344,1.8.656,2.69,1.006.982.385,1.293,1.368.591,2.01a1.58,1.58,0,0,1-1.112.322,9.779,9.779,0,0,1-2.228-.472,16.527,16.527,0,0,1-5.056-2.548,17.385,17.385,0,0,1-2.057-2.336c-.17-.2-.316-.418-.472-.629l-.163.014"
        transform="translate(0.001 -38.335)"
        fill="currentColor"
      />
      <path
        id="Path_120"
        data-name="Path 120"
        d="M58.455,4.766A4.719,4.719,0,0,1,63.248,0a4.743,4.743,0,1,1-.221,9.483,4.7,4.7,0,0,1-4.572-4.718"
        transform="translate(-45.992 0)"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
